import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ion-text-center" }
const _hoisted_3 = { class: "ion-text-center" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "desktop-only"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_JobConfiguration = _resolveComponent("JobConfiguration")!
  const _component_MaargJobConfiguration = _resolveComponent("MaargJobConfiguration")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, { slot: "start" }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Miscellaneous")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("section", null, [
              (_ctx.miscellaneousJobs?.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.translate("There are no miscellaneous jobs right now")), 1),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_ion_button, {
                        fill: "outline",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refreshJobs()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate('retry')) + " ", 1),
                          (_ctx.isRetrying)
                            ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                key: 0,
                                name: "crescent"
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_ion_list, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_list_header, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("Miscellaneous jobs")), 1)
                          ]),
                          _: 1
                        }),
                        (_ctx.maargJobs?.length)
                          ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_header, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_card_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("Shopify bulk query")), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maargJobs, (job, index) => {
                                  return (_openBlock(), _createBlock(_component_ion_item, {
                                    key: index,
                                    button: "",
                                    detail: "",
                                    onClick: ($event: any) => (_ctx.viewMaargJobConfiguration(job.jobTypeEnumId))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(job.enumDescription ? job.enumDescription : job.jobName), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_ion_label, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.getMaargJobStatus(job.jobTypeEnumId)), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                }), 128))
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prepareMiscJobs, (job) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: job.jobId,
                            onClick: ($event: any) => (_ctx.viewJobConfiguration(job)),
                            detail: "",
                            button: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(job.jobName), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_note, { slot: "end" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getJobRuntime(job)), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_infinite_scroll, {
                      onIonInfinite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadMoreMiscellaneousJobs($event))),
                      threshold: "100px",
                      disabled: !_ctx.isMiscellaneousJobsScrollable
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_infinite_scroll_content, {
                          "loading-spinner": "crescent",
                          "loading-text": _ctx.translate('Loading')
                        }, null, 8, ["loading-text"])
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]))
            ]),
            (_ctx.isDesktop)
              ? _withDirectives((_openBlock(), _createElementBlock("aside", _hoisted_5, [
                  (_ctx.currentJob)
                    ? (_openBlock(), _createBlock(_component_JobConfiguration, {
                        status: _ctx.currentJobStatus,
                        type: _ctx.freqType,
                        key: _ctx.currentJob
                      }, null, 8, ["status", "type"]))
                    : (Object.keys(_ctx.currentMaargJob).length)
                      ? (_openBlock(), _createBlock(_component_MaargJobConfiguration, { key: _ctx.currentMaargJob }))
                      : _createCommentVNode("", true)
                ], 512)), [
                  [_vShow, _ctx.currentJob || Object.keys(_ctx.currentMaargJob).length]
                ])
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}