import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "desktop-only"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_MoreJobs = _resolveComponent("MoreJobs")!
  const _component_JobConfiguration = _resolveComponent("JobConfiguration")!
  const _component_MaargJobConfiguration = _resolveComponent("MaargJobConfiguration")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, { slot: "start" }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Product")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("section", null, [
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Sync")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'IMP_PRDTS', status: _ctx.getJobStatus(_ctx.jobEnums['IMP_PRDTS'])}))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Import products")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('IMP_PRDTS')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'SYNC_PRDTS', status: _ctx.getJobStatus(_ctx.jobEnums['SYNC_PRDTS'])}))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Sync products")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('SYNC_PRDTS')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    button: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'ACT_PROD_SHPFY', status: _ctx.getJobStatus(_ctx.jobEnums['ACT_PROD_SHPFY'])}))),
                    detail: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Activate products on Shopify")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('ACT_PROD_SHPFY')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 1,
                            style: {"width":"30%"},
                            animated: ""
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.translate("Sync products and category structures from Shopify into HotWax Commerce and keep them up to date.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.getFilteredMaargJobs()?.length)
                ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Feed")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFilteredMaargJobs(), (job, index) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: index,
                          button: "",
                          detail: "",
                          onClick: ($event: any) => (_ctx.viewMaargJobConfiguration(job.jobTypeEnumId))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(job.enumDescription ? job.enumDescription : job.jobName), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getTemporalExpression(job.jobTypeEnumId, true)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.getMoreJobs({..._ctx.jobEnums, ..._ctx.initialLoadJobEnums}, _ctx.enumTypeId).length)
                ? (_openBlock(), _createBlock(_component_MoreJobs, {
                    key: 1,
                    jobs: _ctx.getMoreJobs({..._ctx.jobEnums, ..._ctx.initialLoadJobEnums}, _ctx.enumTypeId)
                  }, null, 8, ["jobs"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.isDesktop)
              ? _withDirectives((_openBlock(), _createElementBlock("aside", _hoisted_1, [
                  (_ctx.currentJob)
                    ? (_openBlock(), _createBlock(_component_JobConfiguration, {
                        status: _ctx.currentJobStatus,
                        type: _ctx.freqType,
                        key: _ctx.currentJob
                      }, null, 8, ["status", "type"]))
                    : (Object.keys(_ctx.currentMaargJob).length)
                      ? (_openBlock(), _createBlock(_component_MaargJobConfiguration, { key: _ctx.currentMaargJob }))
                      : _createCommentVNode("", true)
                ], 512)), [
                  [_vShow, _ctx.currentJob || Object.keys(_ctx.currentMaargJob).length]
                ])
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}